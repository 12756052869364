import React, { useEffect, useState } from "react";
import "./whereToBuy.scss";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import {
  mbYellowBackground,
  noData,
  searchIcon,
  storeDefault,
  whereToBuyBanner,
  yellowBackground,
} from "../../images";
import { useWindowSize } from "react-use";
import Footer from "../../components/Footer/Footer";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import config from "../../config/config";
import Joi from "joi";

import Map from "./Map";

// const StoreData = [
//   {
//     name: "Spice Corner",
//     address: "121 Lexington Avenue, NY 10016",
//     map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6044.28094863529!2d-73.695829!3d40.758935!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2600a183540e5%3A0xecc3a1961eca0e60!2sHouse%20of%20Spices%20(India)%20Inc.!5e0!3m2!1sen!2sin!4v1716812981937!5m2!1sen!2sin",
//   },
//   {
//     name: "Spice Corner",
//     address: "121 Lexington Avenue, NY 10016",
//     map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.367721078746!2d72.82672157502905!3d19.13537448208149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6228b683d19%3A0xc79c758715cc6c57!2sLaxmi%20Industrial%20Estate%2C%20Suresh%20Nagar%2C%20Andheri%20West%2C%20Mumbai%2C%20Maharashtra%20400053!5e0!3m2!1sen!2sin!4v1719847307691!5m2!1sen!2sin",
//   },
//   {
//     name: "Spice Corner",
//     address: "121 Lexington Avenue, NY 10016",
//     map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.8774126702706!2d72.99449647502725!3d19.06912668213395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c1828cf71f95%3A0x64af80cfe378917a!2sLaxmi%20Foods!5e0!3m2!1sen!2sin!4v1719847446966!5m2!1sen!2sin",
//   },
// ];

const WhereToBuy = () => {
  // =============== VARIABLE & STATES  ===========================

  const { width } = useWindowSize();
  const [activeStore, setActiveStore] = useState(0);
  const [isDefault, setIsDefault] = useState(true);
  const [query, setQuery] = useState("");

  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  const [datas, setDatas] = useState([]);

  const base_url = config.api_url; //without trailing slash
  const image_url = config.image_url + "/product/"; //with trailing slash

  // =============== FUNCTIONS  ===========================

  const handleQueryChange = (e) => {
    const numberRegex = /^[0-9\b]+$/;
    let search = e.target.value;

   
    if (search === "" || numberRegex.test(search)) {
      setQuery(search);
    }
  };

  // const handleSearch = () => {

  // };

  const schema = Joi.object({
    query: Joi.number().required().label("Zip Code"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSearch = async (e) => {
    console.log("form submit");

    e.preventDefault();

    let errors = await validate({ query });
    setFormError(errors);

    console.log({ errors });
    if (Object.keys(errors).length) {
      return;
    }

    setIsDefault(false);
    fetchDatas(query);
  };

  const NotFound = () => {
    // return "No data found";
    return (
      <div className="nodata_wrapper">
        <img src={noData} className="nodata_img" alt="no data" />
        <p className="nodata_head">No Result Found</p>
        <p className="nodata_desc">
          We can't find any item matching your search
        </p>
      </div>
    );
  };

  const Loader = () => {
    // return "loading...";
    return (
      <div className="loader_wrapper">
        {/* <img src={loader} className="loader_img" alt="loader" /> */}
        <div class="loader-circle">
          <div class="loader-circle1 loader-child"></div>
          <div class="loader-circle2 loader-child"></div>
          <div class="loader-circle3 loader-child"></div>
          <div class="loader-circle4 loader-child"></div>
          <div class="loader-circle5 loader-child"></div>
          <div class="loader-circle6 loader-child"></div>
          <div class="loader-circle7 loader-child"></div>
          <div class="loader-circle8 loader-child"></div>
          <div class="loader-circle9 loader-child"></div>
          <div class="loader-circle10 loader-child"></div>
          <div class="loader-circle11 loader-child"></div>
          <div class="loader-circle12 loader-child"></div>
        </div>
        <p className="loader_head">Loading...</p>
        <p className="loader_desc">Loading content, please wait</p>
      </div>
    );
  };

  // =============== HTML RENDERING  ===========================

  const storeList = (
    <>
      <div className="col-md-5 googlmap_col">
        <div className="result_box">
          <h2 className="heading">Results</h2>
          <ul className="store_list_wrapper">
            {datas?.map((item, i) => (
              <li
                className={`store_list ${activeStore === i ? "active" : ""}`}
                key={i}
                onClick={() => setActiveStore(i)}
              >
                <p className="store_name">{item.name}</p>
                <p className="store_address">{item.address}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="col-md-7 googlmap_col">
        <div className="map_box">
          {/* {datas?.map(
            (item, i) =>
              clicked === i && (
                <iframe
                  key={i}
                  title="google map frame"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.8774126702706!2d72.99449647502725!3d19.06912668213395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c1828cf71f95%3A0x64af80cfe378917a!2sLaxmi%20Foods!5e0!3m2!1sen!2sin!4v1719847446966!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  style={{ border: "0", margin: "0", padding: "0" }}
                  allowFullScreen
                  loading="lazy"
                  className="map_frame"
                ></iframe>
              )
          )} */}
          <Map
            lat={datas?.[activeStore]?.latitude}
            lng={datas?.[activeStore]?.longitude}
            mapInfo={datas?.[activeStore]}
          />
        </div>
      </div>
    </>
  );

  // =============== DATA FETCHING  ===========================

  const fetchDatas = async (zip) => {
    let parameters = {};
    setLoading(true);
    const { data } = await http.get(`${base_url}stores/${zip}`, parameters);

    if (data) {
      // console.log({ data });
      setDatas(data);
    }

    setLoading(false);
  };
  // =============== SIDE EFFECTS  ===========================

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // fetchDatas("10016");
  }, []);

  return (
    <>
      <section className="whereto_sec1">
        <CommonBanner
          linkText="Where To Buy"
          title="Where To <br/> Buy"
          img={whereToBuyBanner}
        />
      </section>

      <section className="whereto_sec2">
        <img
          src={width > 767 ? yellowBackground : mbYellowBackground}
          alt="background"
          className="background_img"
        />
        <div className="my_container">
          <div className="head_wrapper">
            <h2 className="heading" data-aos="fade-up">
              Find A Store Near You
            </h2>
            <form
              className="form_wrapper"
              onSubmit={handleSearch}
              data-aos="fade-up"
            >
              <div className="search_container">
                <div className="input_wrapper">
                  <input
                    className="input_field"
                    type="tel"
                    placeholder="Enter Your Zip Code"
                    onChange={handleQueryChange}
                    value={query}
                  />
                  <p className="error">{formError.query}</p>
                </div>
                <button className="search_wrapper">
                  <img
                    src={searchIcon}
                    alt="search icon"
                    className="search_icon"
                  />
                  <p className="search_text">SEARCH</p>
                </button>
              </div>
            </form>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="map_container" data-aos="fade-up">
              <div className="row googlmap_row">
                {isDefault ? (
                  <div className="col-md-12 googlmap_col">
                    <img
                      src={storeDefault}
                      alt="default"
                      className="default_img"
                      data-aos="fade-up"
                    />
                  </div>
                ) : datas.length > 0 ? (
                  storeList
                ) : (
                  <NotFound />
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="whereto_footer">
        <Footer />
      </section>
    </>
  );
};

export default WhereToBuy;
