import React from "react";
import { NavLink } from "react-router-dom";

const HeaderNavItem = ({ navData }) => {
  const productRegex = /product\/[0-9 | a-z]/;

  return (
    <li className="nav_item">
      {navData.linktype === "external" ? (
        <a
          className="nav_link"
          href={navData.mainLink}
          target="_blank"
          rel="noreferrer"
        >
          {navData.mainTitle}
        </a>
      ) : (
        <NavLink
          className={({ isActive }) => {
            return `${isActive ? "nav_link active" : "nav_link"} ${
              productRegex.test(window.location.pathname) && navData.id === 2
                ? "active"
                : ""
            }`;
          }}
          to={navData.mainLink}
        >
          {navData.mainTitle}
        </NavLink>
      )}
    </li>
  );
};

export default HeaderNavItem;
