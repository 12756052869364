import React, { useEffect } from "react";
import "./termsAndConditions.scss";
import {
  greenBackgroundCorner,
  lightGreenBackgroundRect,
  mbGreenBackgroundCorner,
  mbLightGreenBackground,
  termsBanner,
} from "../../images";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { useWindowSize } from "react-use";
import Footer from "../../components/Footer/Footer";
import { homeURL } from "../../components/helpers/constant-words";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="terms_sec1">
        <CommonBanner
          linkText="Terms & Conditions"
          title="Terms & <br /> Conditions"
          img={termsBanner}
          backImg={
            width > 767 ? lightGreenBackgroundRect : mbLightGreenBackground
          }
        />
      </section>

      <section className="terms_sec2">
        <img
          src={width > 767 ? greenBackgroundCorner : mbGreenBackgroundCorner}
          alt="background"
          className="background_img"
        />
        <div className="my_container">
          <div className="privacy_container">
            <div className="para">
              <p className="desc top_desc">
                Welcome to Garvi Gujarat Private Limited! By accessing our
                website <Link to={homeURL} className="link">www.garvigujarat.com</Link>, you agree to comply with the
                following terms and conditions. We recommend that you read them
                carefully before availing our services.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">Privacy:</h2>
              <p className="desc">
                Our Privacy Policy, which governs the use of personal and public
                information, can be found on the relevant webpage of our
                website.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">Copyrights:</h2>
              <p className="desc">
                All content available through Garvi Gujarat Private Limited's
                services, including logos, images, audio clips, text, and other
                data compilations, are protected by Indian laws and belong to
                Garvi Gujarat Private Limited or its content suppliers. Copying
                any content without authorization is strictly prohibited and
                illegal.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">Trademarks:</h2>
              <p className="desc">
                All rights reserved for Garvi Gujarat Private Limited, including
                graphics, page headers, icons, scripts, and service names or any
                other trademarks included in Garvi Gujarat Private Limited or
                its affiliates or content suppliers. The use of our services
                does not grant you the right to copy or use these trademarks.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">Electronic Communication:</h2>
              <p className="desc">
                By using any of Garvi Gujarat Private Limited's services or
                sending an email to us, you consent to receive electronic
                communications from us. You acknowledge that all agreements,
                disclosures, notices, and other electronic communications
                satisfy legal requirements.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">Login Account:</h2>
              <p className="desc">
                You are responsible for maintaining the confidentiality of your
                account and password. You acknowledge that you are responsible
                for the authenticity of the data provided and all activities
                that occur under your account or password. Garvi Gujarat Private
                Limited reserves the right to refuse service, edit or remove
                content, terminate accounts, or cancel orders at its sole
                discretion without liability to you.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">Risks of Loss:</h2>
              <p className="desc">
                Any products purchased through this site using electronic modes
                of payment are shipped in accordance with a shipment contract.
                Garvi Gujarat Private Limited does not hold the risk of loss for
                any products delivered to you.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">
                Disclaimer of Warranties and Limitation of Liability:
              </h2>
              <p className="desc">
                Garvi Gujarat Private Limited services, information, content,
                materials, products, and other services available through the
                website are provided "AS IS" and "AS AVAILABLE," unless
                otherwise specified.
                <br />
                Garvi Gujarat Private Limited makes no representations or
                warranties of any kind, express or implied, regarding its
                services, information, materials, products, or other services
                included on the website or made available to you through the
                website services unless otherwise specified.
                <br />
                To the fullest extent permissible under applicable law,
                Garvi Gujarat Private Limited disclaims all warranties,
                including but not limited to, implied warranties of
                merchantability and fitness for a particular purpose.
                <br />
                Garvi Gujarat Private Limited does not warrant that its
                services, information, materials, products, or other services
                included on the website or made available to you through the
                website services, Garvi Gujarat Private Limited servers,
                electronic communications sent through Garvi Gujarat Private
                Limited are free of viruses or other harmful components.
                <br />
                Garvi Gujarat Private Limited will not be liable for any
                damages arising from the use of its services, information,
                materials, products, or other services included on the website
                or made available to you through the website services, including
                but not limited to direct, indirect, incidental, punitive,
                and/or consequential damages unless otherwise specified.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">Website Coupons:</h2>
              <ul>
                Terms and Conditions to avail coupons are as follows:
                <li>To purchase a coupon, the customer must log in.</li>
                <li>
                  The amount paid for the coupon will be transferred as store
                  credit.
                </li>
                <li>
                  The coupon is valid for three months from the date of
                  purchase.
                </li>
                <li>
                  Any remaining amount after the coupon's expiry will be
                  forfeited and non-refundable.
                </li>
              </ul>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">Disputes:</h2>
              <p className="desc">
                Any dispute or claim arising from the use of Garvi Gujarat
                Private Limited's services will be resolved in accordance with
                Indian laws.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">Disclaimer:</h2>
              <p className="desc">
                Concerns related to products and shipping should be addressed
                within 24 hours of delivery.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
