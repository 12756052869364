import React from "react";
import "./homebanner01.scss";
import BannerCircularProducts from "../BannerProducts/BannerProducts";

import {
  bannerIcon1,
  bannerIcon2,
  bannerIcon3,
  bannerIcon4,
  bannerIcon5,
  bannerillustrations,
  circlebg,
} from "../../images";
import { useWindowSize } from "react-use";

const HomeBanner01 = () => {
  const { width } = useWindowSize();

  return (
    <>
      <div className="banner_01">
        <div className="my_container zero_padding full_width">
          {width > 992 && (
            <img
              src={bannerillustrations}
              alt="illustration"
              className="illustration"
              loading="lazy"
            />
          )}
          <div className="floating_icons">
            <img
              src={bannerIcon1}
              alt="floating"
              className="floating-icon floating-icon-1"
              loading="lazy"
            />
            <img
              src={bannerIcon2}
              alt="floating"
              className="floating-icon floating-icon-2"
              loading="lazy"
            />
            <img
              src={bannerIcon3}
              alt="floating"
              className="floating-icon floating-icon-3"
              loading="lazy"
            />
            <img
              src={bannerIcon4}
              alt="floating"
              className="floating-icon floating-icon-4"
              loading="lazy"
            />
            <img
              src={bannerIcon5}
              alt="floating"
              className="floating-icon floating-icon-5"
              loading="lazy"
            />
          </div>
          <h1 className="banner_heading">
            Authentic Flavors From The Heart Of Gujarat
          </h1>
          <div className="product_animation">
            <img src={circlebg} alt="circle" className="circlebg" loading="lazy" />
            <BannerCircularProducts />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBanner01;
