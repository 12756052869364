import React, { useState } from "react";
import "./Maps.scss";

// import PhoneLogo from "../images/locator/phone_logo.svg";
import { phoneLogo } from "../../images";

import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

const style = {
  width: "100%",
  height: "100%",
};
// const containerStyle = {
//   position: "absolute",
//   width: "41.5%",
//   height: "53.750em",
// };

export class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapLoaded: false,
    };
  }

  componentDidMount() {
    this.setState({
      selectedPlace: this.props.mapInfo,
      activeMarker: null,
      showingInfoWindow: true,
    });
  }
  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    return (
      <Map
        className="mapDimension"
        google={this.props.google}
        zoom={19}
        style={style}
        center={{
          lat: this.props.lat,
          lng: this.props.lng,
        }}
        onClick={this.onMapClicked}
        // onIdle={this.handleMapIdle}
      >
        <Marker
          position={{ lat: this.props.lat, lng: this.props.lng }}
          onClick={this.onMarkerClick}
          //   title={"Cvs Pharmacy"}
          name={
            <div className="pop_up_container">
              <div className="pop_up_name">{this.props.mapInfo.name}</div>
              <div className="pop_up_address">
                {this.props.mapInfo.address},{this.props.mapInfo.state}{" "}
                {this.props.mapInfo.zip}
              </div>
              <div className="pop_up_phone_con">
                <div className="pop_up_phone_img">
                  <img src={phoneLogo} alt="phone logo"  />
                </div>
                <div className="pop_up_phno">
                  <a href={"tel:" + this.props.mapInfo.phone}>
                    {this.props.mapInfo.phone}
                  </a>
                </div>
              </div>

              <a
                className="pop_up_location"
                href={this.props.mapInfo.mapLink}
                target="_blank"
                rel="noreferrer"
              >
                View on Maps
              </a>
            </div>
          }
        />

        <InfoWindow
          onOpen={this.windowHasOpened}
          onClose={this.windowHasClosed}
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
        >
          <div>
            <h1>{this.state.selectedPlace.name}</h1>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyDwvFHuiiy0Y4_AAvAEFtWrrjh6Jw0xk0U",
})(MapContainer);
