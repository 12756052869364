import React, { useEffect, useRef, useState } from "react";
import "./faqs.scss";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import {
  faqBanner,
  greenBackgroundCorner,
  lightGreenBackgroundRect,
  mbGreenBackgroundCorner,
  mbLightGreenBackground,
  yellowDownArrow,
  yellowUpArrow,
} from "../../images";
import { useWindowSize } from "react-use";
import { faqData } from "../../data/faqData";
import Footer from "../../components/Footer/Footer";

const Faqs = () => {
  const { width } = useWindowSize();
  const [clicked, setClicked] = useState();
  const accRef = useRef(null);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active
      return setClicked(null);
    }

    setClicked(index);
  };

  const faqList = faqData.map((item, index) => (
    <div
      className="accordion_container"
      ref={clicked === index ? accRef : null}
      data-aos="fade-up"
    >
      <div
        className="title_wrapper"
        onClick={() => toggle(index)}
        key={item.id}
      >
        <h1 className={`title ${clicked === index ? "active" : ""}`}>
          {item.title}
        </h1>
        <img
          src={clicked === index ? yellowUpArrow : yellowDownArrow}
          alt="arrow"
          className="arrow_icon"
        />
      </div>
      {/* <div className={`desc_wrapper ${clicked === index ? "active" : ""}`}> */}
      <div className="desc_wrapper">
        {clicked === index
          ? item.data.map((data, i) => (
              <div className="data_wrapper">
                <h4 className="quest">{data.heading}</h4>
                <p
                  className="description"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                ></p>
              </div>
            ))
          : ""}
      </div>
    </div>
  ));

  const scrollToAccordion = () => {
    if (accRef.current) {
      accRef.current.scrollIntoView({
        top: 0,
        behavior: "smooth",
        // block: "start",
      });
    }
  };

  useEffect(() => {
    scrollToAccordion();
  }, [clicked]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="faq_sec1">
        <CommonBanner
          linkText="FAQs"
          title="Frequently <br /> Asked Questions"
          img={faqBanner}
          backImg={width > 767 ? lightGreenBackgroundRect : mbLightGreenBackground}
        />
      </section>

      <section className="faq_sec2">
        <img
          src={width > 767 ? greenBackgroundCorner : mbGreenBackgroundCorner}
          alt="background"
          className="background_img"
        />
        <div className="my_container">
          <div className="faq_wrapper">{faqList}</div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Faqs;
