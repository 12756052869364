



import moment from "moment";


const helper = {
  

  formatDate: (date, format = "YYYY-MM-DD") => {
    return moment(date).format(format);
  },

  
};
export default helper;
