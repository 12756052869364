import React, { useEffect, useState } from "react";
import "./header.scss";

import { Link, NavLink, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import HeaderNavItem from "./HeaderNavItem";
import {
  aboutURL,
  contactUsURL,
  homeURL,
  productsURL,
  whereToBuyURL,
} from "../helpers/constant-words";
import { garvisvglogo } from "../../images";
const body = document.querySelector("body");

const menuData = [
  {
    id: 2,
    type: "text",
    mainTitle: "ABOUT US",
    mainLink: aboutURL,
  },
  {
    id: 3,
    type: "text",
    mainTitle: "PRODUCTS",
    mainLink: productsURL,
  },
  {
    id: 4,
    type: "text",
    mainTitle: "WHERE TO BUY",
    mainLink: whereToBuyURL,
  },
  // {
  //   id: 5,
  //   type: "text",
  //   mainTitle: "RECIPES",
  //   mainLink: recipesURL,
  // },
  // {
  //   id: 6,
  //   type: "text",
  //   mainTitle: "MEDIA",
  //   mainLink: "media",
  // },
  {
    id: 7,
    type: "text",
    mainTitle: "CONTACT US",
    mainLink: contactUsURL,
  },
];

const Header = () => {
  const { width } = useWindowSize();
  const { pathname: locationPathname } = useLocation();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isBg, setIsbg] = useState(false);
  const [navActiveIndex, setNavActiveIndex] = useState(null);

  useEffect(() => {
    body.style.overflow = "auto";
    // if (locationPathname === "/") {
    //   setIsbg(false);
    // } else {
    //   setIsbg(true);
    // }
    setIsMenuActive(false);
  }, [locationPathname]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  });

  const handleScroll = () => {
    // if (window.location.pathname === "/")
      if (window.pageYOffset > 60) {
        setIsbg(true);
      } else {
        setIsbg(false);
      }
  };

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  };

  const handleNavIndex = (i) => {
    if (navActiveIndex === i) {
      setNavActiveIndex(null);
    } else {
      setNavActiveIndex(i);
    }
  };

  const navlinkList = menuData.map((nav, i) => (
    <li className="link_item" key={`menu-item-${menuData[i].id}`}>
      <NavLink
        className={`nav_tab ${(navData) =>
          navData.isActive ? "active" : "none"}`}
        to={nav.mainLink}
      >
        {nav.mainTitle}
      </NavLink>
    </li>
  ));

  return (
    <>
      <header className={`primary-header ${isBg ? "active" : null}`}>
        {width > 992 ? (
          <>
            <div className="my_container">
              <div className="navlinks_wrapper">
                <div className="logo_wrapper">
                  <Link to={homeURL}>
                    <img
                      className="logo_img"
                      src={garvisvglogo}
                      alt="garvi Logo"
                      onClick={scrollHandler}
                    />
                  </Link>
                </div>
                <ul className="link_list">{navlinkList}</ul>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="my_container">
              <div className="logo_wrapper">
                <Link className="logo_link" to={homeURL}>
                  <img
                    src={garvisvglogo}
                    alt="garvi logo"
                    className="garvi_logo img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div
              className={`mobile_header_wrapper ${
                isMenuActive ? "active" : ""
              }`}
            >
              <ul className="mobile_links_wrapper">
                {menuData.map((navData, i) => (
                  <HeaderNavItem
                    navData={navData}
                    key={parseInt(navData.id)}
                    arrIndex={i}
                    handleNavIndex={handleNavIndex}
                    navActiveIndex={navActiveIndex}
                  />
                ))}
              </ul>
            </div>

            {/* mobile UI start */}
            <div
              className={`hamburger_lines ${
                isMenuActive ? "active" : "" || isBg ? "black" : ""
              }`}
              onClick={handleHamburgerClick}
            >
              <div className="line line1"></div>
              <div className="line line2"></div>
              <div className="line line3"></div>
            </div>
          </>
        )}
      </header>
    </>
  );
};

export default Header;
