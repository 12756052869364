import React, { useEffect } from "react";
import "./about.scss";
import {
  aboutBanner,
  menPlaying,
  greenBackground,
  yellowBackground,
  womenPlaying,
  mbGreenBackground,
  mbYellowBackground,
} from "../../images";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { aboutProductData, savorData } from "../../data/aboutData";
import { useWindowSize } from "react-use";
import Counteritem from "../../components/CounterItem/CounterItem";
import Footer from "../../components/Footer/Footer";

const About = () => {
  const { width } = useWindowSize();
  const productList = aboutProductData.map((item, i) => (
    <div className="product_details" key={item.heading + i} data-aos="fade-up">
      <div className="img_wrapper">
        <img
          src={item.img}
          width={41.24}
          height={41.31}
          alt="icon"
          className="product_icon"
          loading="lazy"
        />
      </div>
      <div className="product_data">
        {item.numbCounter && (
          <Counteritem
            startCount={0}
            endCount={item.countNumb ? item.countNumb : ""}
            duration={item.time}
            countTitle={item.heading}
            plus
            // aboveThousand
          />
        )}
        {item.numbCounter ? null : (
          <h4 className="sub_heading">{item.heading}</h4>
        )}
        <p className="sub_desc">{item.desc}</p>
      </div>
    </div>
  ));

  const savorList = savorData.map((item, i) => (
    <div className="content_wrapper" key={item.title + i}>
      <h2 className="heading" data-aos="fade-up">
        {item.title}
      </h2>
      <div className="desc_wrapper" data-aos="fade-up">
        {item?.description?.map((desc, i) => (
          <p
            className="desc"
            key={item.description + i}
            dangerouslySetInnerHTML={{ __html: desc }}
          ></p>
        ))}
      </div>
    </div>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
    // window.history.scrollRestoration = 'manual'
  }, []);

  return (
    <>
      <section className="about_sec1">
        <CommonBanner linkText="About Us" title="About Us" img={aboutBanner} />
      </section>

      <section className="about_sec2">
        <img
          src={width > 767 ? greenBackground : mbGreenBackground}
          alt="background"
          className="background_img"
        />
        <div className="my_container">
          <div className="product_row">{productList}</div>
        </div>
      </section>

      <section className="about_sec3">
        <img
          src={width > 767 ? yellowBackground : mbYellowBackground}
          alt="background"
          className="background_img"
        />
        <img
          src={menPlaying}
          width={159.31}
          height={328.64}
          alt="men"
          className="men_dandiya_img"
          loading="lazy"
        />
        <img
          src={womenPlaying}
          width={159.31}
          height={335.63}
          alt="women"
          className="women_dandiya_img"
          loading="lazy"
        />
        <div className="my_container">{savorList}</div>
      </section>
      <section className="about_footer">
        <Footer />
      </section>
    </>
  );
};

export default About;
