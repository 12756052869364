import React from "react";
import "./commoncta.scss";
import { Link } from "react-router-dom";
import { rightArrow } from "../../images";

const CommonCta = ({ link, linkText, linkCta, externalLink }) => {
  return (
    <>
      <section className="comm_cta_sec1">
        {linkCta ? (
          externalLink ? (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="cta_link"
            >
              {linkText}{" "}
              <img src={rightArrow} alt="right arrow" className="right_arrow" />
            </a>
          ) : (
            <Link to={link} className="cta_link">
              {linkText}{" "}
              <img src={rightArrow} alt="right arrow" className="right_arrow" />
            </Link>
          )
        ) : (
          <button className="cta_link">
            {linkText}{" "}
            <img src={rightArrow} alt="right arrow" className="right_arrow" />
          </button>
        )}
      </section>
    </>
  );
};

export default CommonCta;
