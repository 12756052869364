import React from "react";
import "./footer.scss";
import {
  aboutURL,
  contactUsURL,
  faqsURL,
  homeURL,
  privacyPolicyURL,
  productsURL,
  termsURL,
  whereToBuyURL,
} from "../helpers/constant-words";
import { Link } from "react-router-dom";
import {
  garvisvglogo,
  lightGreenBackground,
  mbLightGreenBackground,
} from "../../images";
import { useWindowSize } from "react-use";

const Footer = () => {
  const { width } = useWindowSize();
  const today = new Date();

  const mbMenuArr = [
    {
      linkName: "Quick Links",
      subMenu: [
        {
          redirectLink: aboutURL,
          subLinkName: "About Us",
        },
        {
          redirectLink: productsURL,
          subLinkName: "Products",
        },
        // {
        //   redirectLink: recipesURL,
        //   subLinkName: "Recipes",
        // },
        // {
        //   redirectLink: "media",
        //   subLinkName: "Media",
        // },
      ],
    },
    {
      linkName: "SUPPORT",
      subMenu: [
        {
          redirectLink: whereToBuyURL,
          subLinkName: "Where To Buy",
        },
        {
          redirectLink: contactUsURL,
          subLinkName: "Contact Us",
        },
        {
          redirectLink: faqsURL,
          subLinkName: "FAQ",
        },
      ],
    },
    {
      linkName: "HELP",
      subMenu: [
        {
          redirectLink: privacyPolicyURL,
          subLinkName: "Privacy Policy",
        },
        {
          redirectLink: termsURL,
          subLinkName: "Terms Of Service",
        },
        // {
        //   redirectLink: productsURL,
        //   subLinkName: "Explore Products",
        // },
      ],
    },
  ];

  return (
    <>
      <footer className="footer_sec1">
        <img
          src={width > 767 ? lightGreenBackground : mbLightGreenBackground}
          alt="background"
          className="background_img"
        />
        <div className="my_container">
          <div className="row g-0">
            <div className="col-sm-10 col-md-10">
              <div className="row footer_box1">
                <div className="col-sm-4 col-md-4">
                  <article className="footer_col">
                    <div className="logo_wrapper">
                      <Link to={homeURL}>
                        <img
                          width={272}
                          height={131}
                          src={garvisvglogo}
                          alt="logo"
                          className="footer_logo"
                          loading="lazy"
                        />
                      </Link>
                      {/* <p className="logo_desc">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      </p> */}
                    </div>
                  </article>
                </div>
                <div className="col-sm-4 col-md-4">
                  <article className="footer_col">
                    <h4 className="footer_title">{mbMenuArr[0].linkName}</h4>
                    <ul className="link_list">
                      {mbMenuArr[0].subMenu.map((data, i) => (
                        <li className="list_item" key={data.subLinkName + i}>
                          <Link className="navlink" to={data.redirectLink}>
                            {data.subLinkName}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </article>
                </div>

                <div className="col-sm-4 col-md-4">
                  <article className="footer_col">
                    <h4 className="footer_title">{mbMenuArr[1].linkName}</h4>
                    <ul className="link_list">
                      {mbMenuArr[1].subMenu.map((data, i) => (
                        <li className="list_item" key={data.subLinkName + i}>
                          <Link className="navlink" to={data.redirectLink}>
                            {data.subLinkName}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </article>
                </div>
              </div>
            </div>
            <div className="col-sm-2 col-md-2">
              <div className="footer_box1">
                <article className="footer_col">
                  <h4 className="footer_title">{mbMenuArr[2].linkName}</h4>
                  <ul className="link_list link_list_1">
                    {mbMenuArr[2].subMenu.map((data, i) => (
                      <li className="list_item" key={data.subLinkName + i}>
                        <Link className="navlink" to={data.redirectLink}>
                          {data.subLinkName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </article>
              </div>
            </div>
          </div>

          <div className="copyright_container">
            {/* <div className="copright_box copright_box-2 order-md-2">
               <div className="social_wrapper">
                <a
                  href={() => false}
                  className="social_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    width={48}
                    height={48}
                    src={facebookFooter}
                    alt=""
                    className="social_icon"
                    loading="lazys"
                  />
                </a>
                <a
                  href={() => false}
                  className="social_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    width={48}
                    height={48}
                    src={instagramFooter}
                    alt=""
                    className="social_icon"
                    loading="lazys"
                  />
                </a>
                <a
                  href={() => false}
                  className="social_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    width={48}
                    height={48}
                    src={linkedinFooter}
                    alt=""
                    className="social_icon"
                    loading="lazys"
                  />
                </a>
                <a
                  href={() => false}
                  className="social_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    width={48}
                    height={48}
                    src={youtubeFooter}
                    alt=""
                    className="social_icon"
                    loading="lazys"
                  />
                </a>
              </div> 
            </div> */}
            <div className="copright_box copright_box-1 order-md-1">
              <p className="copyright_text">
                Garvigujarat@Copyright{today.getFullYear()}
              </p>
              {/* {width < 767 && (
                <p className="copyright_text">
                  Crafted by{" "}
                  <a
                    href="https://www.togglehead.in/"
                    target="_blank"
                    rel="noreferrer"
                    className="copyright_crafted"
                  >
                    Togglehead
                  </a>
                </p>
              )} */}
            </div>
            {/* {width > 767 && (
              <div className="copright_box copright_box_1 copright_box-3 order-md-3">
                <p className="copyright_text">
                  Crafted by{" "}
                  <a
                    href="https://www.togglehead.in/"
                    target="_blank"
                    rel="noreferrer"
                    className="copyright_crafted"
                  >
                    Togglehead
                  </a>
                </p>
              </div>
            )} */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
