import React from "react";
import "./commonbanner.scss";
import { comBanBackgound } from "../../images";
import { Link } from "react-router-dom";
import { homeURL } from "../helpers/constant-words";
import { useWindowSize } from "react-use";

const CommonBanner = ({ img, title, linkText, link, backImg }) => {
  const { width } = useWindowSize();
  return (
    <>
      <div className="com_banner_container" data-aos="fade">
        <img src={backImg ? backImg : comBanBackgound} alt="banner" className="banner_back" loading="lazy"/>
        <div className="my_container">
          {width > 767 && (
            <div className="breadcrumb">
              <div className="link_wrapper">
                <Link to={homeURL} className="link">
                  Home
                </Link>
                <Link to={link} className={`link disabled`}>
                  {" | " + linkText}
                </Link>
              </div>
            </div>
          )}
          <div className="banner_wrapper">
            <div className="row banner_row">
              <div className="col-md-4 banner_col zero_padding">
                <div className="content_wrapper">
                  <h1 className="banner_heading" dangerouslySetInnerHTML={{__html: title}}></h1>
                </div>
              </div>
              <div className="col-md-8 banner_col zero_padding">
                <div className="image_wrapper">
                  <img
                    src={img}
                    alt="banner"
                    className="banner_img"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonBanner;
