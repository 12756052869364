import { images } from "../images";

export const recipesData = [
    {
        id: 1,
        img: images.recipes1.image,
        alt: images.recipes1.alt,
        name: "Name of the Recipe Text Here",
        date: "December 12, 2022",
        link: "https://www.youtube.com/watch?v=lPrNTCMkw1A&t=19s&ab_channel=LaxmiFoodsBrand",
    },
    {
        id: 2,
        img: images.recipes2.image,
        alt: images.recipes2.alt,
        name: "Name of the Recipe Text Here",
        date: "December 12, 2022",
        link: "https://www.youtube.com/watch?v=SO7TdK-qen8&ab_channel=LaxmiFoodsBrand",
    },
    {
        id: 3,
        img: images.recipes3.image,
        alt: images.recipes3.alt,
        name: "Name of the Recipe Text Here",
        date: "December 12, 2022",
        link: "https://www.youtube.com/watch?v=xHorEs8SepQ&t=4s&ab_channel=LaxmiFoodsBrand",
    },
    {
        id: 4,
        img: images.recipes1.image,
        alt: images.recipes1.alt,
        name: "Name of the Recipe Text Here",
        date: "December 12, 2022",
        link: "https://www.youtube.com/watch?v=lPrNTCMkw1A&t=19s&ab_channel=LaxmiFoodsBrand",
    },
    {
        id: 5,
        img: images.recipes2.image,
        alt: images.recipes2.alt,
        name: "Name of the Recipe Text Here",
        date: "December 12, 2022",
        link: "https://www.youtube.com/watch?v=SO7TdK-qen8&ab_channel=LaxmiFoodsBrand",
    },
    {
        id: 6,
        img: images.recipes3.image,
        alt: images.recipes3.alt,
        name: "Name of the Recipe Text Here",
        date: "December 12, 2022",
        link: "https://www.youtube.com/watch?v=xHorEs8SepQ&t=4s&ab_channel=LaxmiFoodsBrand",
    },
];

export const recipesSwiperData = [
    {
        id: 1,
        img: images.recipeSlider1.image,
        alt: images.recipeSlider1.alt,
        videoLink: "https://www.youtube.com/watch?v=lPrNTCMkw1A&t=19s&ab_channel=LaxmiFoodsBrand",
    },
    {
        id: 2,
        img: images.recipeSlider1.image,
        alt: images.recipeSlider1.alt,
        videoLink: "https://www.youtube.com/watch?v=SO7TdK-qen8&ab_channel=LaxmiFoodsBrand",
    },
    {
        id: 3,
        img: images.recipeSlider1.image,
        alt: images.recipeSlider1.alt,
        videoLink: "https://www.youtube.com/watch?v=xHorEs8SepQ&t=4s&ab_channel=LaxmiFoodsBrand",
    },
    {
        id: 4,
        img: images.recipeSlider1.image,
        alt: images.recipeSlider1.alt,
        videoLink: "https://www.youtube.com/watch?v=lPrNTCMkw1A&t=19s&ab_channel=LaxmiFoodsBrand",
    },
    {
        id: 5,
        img: images.recipeSlider1.image,
        alt: images.recipeSlider1.alt,
        videoLink: "https://www.youtube.com/watch?v=SO7TdK-qen8&ab_channel=LaxmiFoodsBrand",
    },
    {
        id: 6,
        img: images.recipeSlider1.image,
        alt: images.recipeSlider1.alt,
        videoLink: "https://www.youtube.com/watch?v=xHorEs8SepQ&t=4s&ab_channel=LaxmiFoodsBrand",
    },
];