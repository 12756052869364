import React from "react";
import "./bannerproducts.scss";
import { productCircle } from "../../images";

const BannerCircularProducts = () => {

  return (
    <div id="parent-circle">
      <div className="circle">
        <img
          src={productCircle}
          alt="packets"
          className="product_img"
          // loading="lazy"
        />
      </div>
    </div>
  );
};

export default BannerCircularProducts;
