// routes name
export const homeURL = "/";
export const aboutURL = "/about-us";
export const contactUsURL = "/contact-us";
export const whereToBuyURL = "/where-to-buy";
export const recipesURL = "/recipes";
export const productsURL = "/products";
export const faqsURL = "/faqs";
export const privacyPolicyURL = "/privacy-policy";
export const termsURL = "/terms-and-conditions";


