import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  aboutURL,
  contactUsURL,
  faqsURL,
  homeURL,
  privacyPolicyURL,
  productsURL,
  termsURL,
  whereToBuyURL,
  recipesURL,
} from "./components/helpers/constant-words";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import ContactUs from "./pages/ContactUs/ContactUs";
import WhereToBuy from "./pages/WhereToBuy/WhereToBuy";
import Products from "./pages/Products/Products";
import Faqs from "./pages/Faqs/Faqs";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import Header from "./components/Header/Header";
import Recipes from "./pages/Recipes/Recipes";
// import Footer from "./components/Footer/Footer";

const Routing = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path={homeURL} element={<Home />} />
        <Route path={aboutURL} element={<About />} />
        <Route path={contactUsURL} element={<ContactUs />} />
        <Route path={whereToBuyURL} element={<WhereToBuy />} />
        {/* <Route path={recipesURL} element={<Recipes />} /> */}
        <Route path={productsURL} element={<Products />} />
        <Route path={faqsURL} element={<Faqs />} />
        <Route path={privacyPolicyURL} element={<PrivacyPolicy />} />
        <Route path={termsURL} element={<TermsAndConditions />} />
        {/* <Route exact path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} /> */}
      </Routes>
      {/* <Footer /> */}
    </>
  );
};
export default Routing;
