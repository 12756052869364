import { heartIcon, productIcon, tasteIcon } from "../images";

export const aboutProductData = [
  {
    id: 1,
    img: tasteIcon,
    heading: "Authentic Taste ",
    // desc: "Lorem Ipsum Set Dolor Magna Dummy Text",
  },
  {
    id: 2,
    img: productIcon,
    heading: "Products ",
    // desc: "Lorem Ipsum Set Dolor Magna Dummy Text",
    numbCounter: true,
    countNumb: 25,
    time: 3,
  },
  {
    id: 3,
    img: heartIcon,
    heading: "Directly from the heart of Gujarat",
    // desc: "Lorem Ipsum Set Dolor Magna Dummy Text",
  },
  // {
  //   id: 4,
  //   heading: "Lorem Ipsum",
  //   desc: "Lorem Ipsum Set Dolor Magna Dummy Text",
  // },
];

export const savorData = [
  {
    id: 1,
    title: "Savor The Authenticity Of Garvi Gujarat",
    description: [
      `At Garvi Gujarat, we bring you an authentic taste of Gujarat with our range of traditional snacks. Each item is crafted with the finest ingredients and time-honored methods, celebrating the richness of our culture and traditions.`,
      `Just as Gujarat’s handicrafts and vibrant dance forms are uniquely captivating, our snacks are crafted to be equally distinctive. With every bite, you'll savor the heritage and flavor that define our beloved Gujarat.`,
      `As a renowned name in Gujarat snacking, we take pride in delivering the authentic taste of home to our customers. Join us in embracing the spirit of "Jalsa Ane Masti" with Garvi Gujarat and indulge in the true cultural essence of Gujarat!`,
    ],
  },
];
