import React, { useEffect, useRef, useState } from "react";
import "./home.scss";
import {
  cardBack,
  dandiyaDance,
  foodMaking,
  greenBackground,
  greenCircle,
  homebannerbg,
  leftWhiteArrow,
  lightGreenBackground,
  mbGreenBackground,
  mbLightGreenBackground,
  mbYellowBackground,
  rightWhiteArrow,
  shadow,
  tickerbg,
  whitPlayIcon,
  yellowBackground,
} from "../../images";
import { useWindowSize } from "react-use";
import {
  commSwiperData,
  mediaSwiperData,
  tasteData,
} from "../../data/homeData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import FsLightbox from "fslightbox-react";
import CommonCta from "../../components/CommonCta/CommonCta";
import HomeBanner01 from "../../components/HomeBanner01/HomeBanner01";
import Footer from "../../components/Footer/Footer";
import http from "../../helpers/http";
import config from "../../config/config";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_usaLow from "@amcharts/amcharts5-geodata/usaLow";
import {
  aboutURL,
  productsURL,
  whereToBuyURL,
} from "../../components/helpers/constant-words";
import { EffectCoverflow } from "swiper/modules";
import helper from "../../helpers/helper";

const tickerArr = [
  "Khakra a day keeps the hunger away",
  "Don’t Think before you Snack",
  "Flavors of Home",
  "Don’t Think before you Snack",
  "Khakra a day keeps the hunger away",
  "Flavors of Home",
  "Khakra a day keeps the hunger away",
  "Don’t Think before you Snack",
  "Flavors of Home",
  "Don’t Think before you Snack",
  "Khakra a day keeps the hunger away",
  "Flavors of Home",
  "Khakra a day keeps the hunger away",
  "Don’t Think before you Snack",
  "Flavors of Home",
  "Don’t Think before you Snack",
  "Khakra a day keeps the hunger away",
  "Flavors of Home",
  "Khakra a day keeps the hunger away",
  "Don’t Think before you Snack",
  "Flavors of Home",
  "Don’t Think before you Snack",
  "Khakra a day keeps the hunger away",
  "Flavors of Home",
];

const Home = () => {
  // =============== VARIABLE & STATES  ===========================
  const { width } = useWindowSize();
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");
  const mapRef = useRef(null);

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [media, setMedia] = useState([]);
  const [commercials, setCommercials] = useState([]);
  const [activeCategories, setActiveCategories] = useState(["all"]);

  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const [dataCount, setdataCount] = useState(0);

  const dataLimit = 4;

  const base_url = config.api_url; //without trailing slash
  const image_url = config.image_url + "/product/"; //with trailing slash
  const azure_object_url = config.azure_object_url;

  // =============== FUNCTIONS  ===========================

  const handleWatchClick = (videoLink) => {
    setIsVideoOpen(!isVideoOpen);
    setVidLink(videoLink);
  };

  const handleMbategoryhange = (e) => {
    let category = e.target.value;

    setActiveCategories([category]);
  };

  // =============== DATA FETCHING  ===========================

  const fetchProducts = async () => {
    let parameters = {};

    if (activeCategories.length && !activeCategories.includes("all")) {
      parameters.categories = activeCategories;
    }

    const { data } = await http.post(
      base_url + "products/featured",
      parameters
    );

    if (data) {
      setProducts(data);
    }
  };

  const fetchCategories = async () => {
    const { data } = await http.get(base_url + "product-categories");

    if (data) {
      setCategories([{ _id: "all", title: "All" }, ...data]);
    }
  };

  const fetchMedia = async () => {
    const { data } = await http.get(base_url + "media");

    if (data) {
      setMedia(data);
    }
  };

  const fetchCommercials = async () => {
    const { data } = await http.get(base_url + "commercials");

    if (data) {
      setCommercials(data);
    }
  };
  // =============== SIDE EFFECTS  ===========================

  useEffect(() => {
    fetchProducts();
    fetchCategories();
    fetchMedia();
    fetchCommercials();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [activeCategories]);

  // =============== HTML RENDERING  ===========================

  const tasteList = tasteData.map((item, i) => (
    <div className="col_data" key={item.title + i} data-aos="fade-up">
      <img src={greenCircle} alt="green circle" className="green_circle" />
      <div className="details_wrapper">
        <img
          src={item.img}
          alt={item.alt}
          className={`details_img ${item.class ? item.class : ""}`}
        />
        <h2 className="title">{item.title}</h2>
        <p className="desc">{item.desc}</p>
      </div>
    </div>
  ));

  const tasteSwiperList = tasteData.map((item, i) => (
    <SwiperSlide key={item.title + i}>
      <div className="col_data" key={i} data-aos="fade-up">
        <img src={greenCircle} alt="green circle" className="green_circle" />
        <div className="details_wrapper">
          <img
            src={item.img}
            alt={item.alt}
            className={`details_img ${item.class ? item.class : ""}`}
          />
          <h6 className="title">{item.title}</h6>
          <p className="desc">{item.desc}</p>
        </div>
      </div>
    </SwiperSlide>
  ));

  const mbFilterList = categories?.map((item, i) => (
    <option value={item._id} key={i}>
      {item.title}
    </option>
  ));

  const gujFoodList = products?.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="data_box">
        <img src={cardBack} alt="" className="card_back" />
        <div className="data_wrapper">
          <div className="productimg_wrapper">
            <img
              src={azure_object_url + item.image}
              className="product_img"
              alt="products images"
            />
            <img src={shadow} alt="shadow" className="shadow_img" />
          </div>
          <div className="details_wrapper">
            <h6 className="name">{item.title}</h6>
          </div>
        </div>
      </div>
    </SwiperSlide>
  ));

  const commSwiperList = commercials.map((item, i) => (
    <SwiperSlide key={item.id + i}>
      <div className="img_wrapper">
        <img
          src={`${azure_object_url}${item.thumbnail}`}
          alt={item?.thumbnail}
          className="slider_img"
        />
        <img
          src={whitPlayIcon}
          alt="play icon"
          className="play_icon"
          onClick={() => handleWatchClick(item.video_url)}
        />
      </div>
    </SwiperSlide>
  ));

  const mediaSwiperList = media.map((item, i) => (
    <SwiperSlide key={item.title + i}>
      <div className="data_wrapper">
        <div className="img_wrapper">
          <img
            src={`${azure_object_url}${item.thumbnail}`}
            alt={item?.alt}
            className="product_img"
          />
          <img
            src={whitPlayIcon}
            alt="play"
            className="play_icon"
            onClick={() => handleWatchClick(item.video_url)}
          />
        </div>
        <div className="details_wrapper">
          <h6 className="name">{item.title}</h6>
          <p className="date">
            {helper.formatDate(item.date, config.date_format)}
          </p>
        </div>
      </div>
    </SwiperSlide>
  ));

  const tickerList = tickerArr.map((ticker, i) => (
    <React.Fragment key={`ticker-${ticker + i}`}>
      <SwiperSlide className="ticker_slide" key={`ticker-${ticker + i}`}>
        <h4 className="ticker_title">{ticker}</h4>
      </SwiperSlide>
    </React.Fragment>
  ));

  useEffect(() => {
    const usaMap = am5.Root.new("chartdiv");
    usaMap.setThemes([am5themes_Animated.new(usaMap)]);
    usaMap._logo.dispose();

    const chart = usaMap.container.children.push(
      am5map.MapChart.new(usaMap, {
        projection: am5map.geoAlbersUsa(),
        maxZoomLevel: 1,
        panX: "none",
        panY: "none",
        wheelX: "none",
        wheelY: "none",
        pinchZoom: false,
      })
    );

    const polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(usaMap, {
        geoJSON: am5geodata_usaLow,
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      fill: am5.color(0x006341),
    });

    const pointSeries = chart.series.push(
      am5map.ClusteredPointSeries.new(usaMap, {
        groupIdField: "state",
      })
    );

    pointSeries.bullets.push(() => {
      const locationIconPath =
        "M12 2C8.13 2 5 5.13 5 9c0 4.25 7 13 7 13s7-8.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";

      const locationIcon = am5.Graphics.new(usaMap, {
        svgPath: locationIconPath,
        scale: width > 600 ? 1 : 1.5,
        tooltipY: -5,
        fill: am5.color(0xb1da5b),
        tooltipText: "{title} ({state})",
        cursorOverStyle: "pointer",
        centerX: am5.p50,
        centerY: am5.p100,
      });

      // Define the hover state
      locationIcon.states.create("hover", {
        fill: am5.color(0xffffff),
        scale: width > 600 ? 1.5 : 2,
      });

      locationIcon.events.on("hit", {
        fill: am5.color(0xffffff),
        scale: 1.5,
      });

      return am5.Bullet.new(usaMap, {
        sprite: locationIcon,
      });
    });

    const stores = [
      {
        title: "INDIA FOODS-- IDAHO",
        state: "ID",
        lng: -116.256572,
        lat: 43.6195029,
        city: "Boise",
      },
      {
        title: "COSMOS INDIAN STORE",
        state: "KS",
        lng: -95.2758378,
        lat: 38.970871,
        city: "Lawrence",
      },
      {
        title: "NEW INDIAN GROCERY",
        state: "AL",
        lng: -88.1406144,
        lat: 30.6733827,
        city: "Mobile",
      },
      {
        title: "ALARAN INC",
        state: "GA",
        lng: -84.4975966,
        lat: 33.9449797,
        city: "Marietta",
      },
      {
        title: "SPICE OF LIFE",
        state: "WV",
        lng: -81.5925855,
        lat: 38.327881,
        city: "Charleston",
      },
    ];

    stores.forEach((store) => {
      pointSeries.data.push({
        geometry: { type: "Point", coordinates: [store.lng, store.lat] },
        title: store.title,
        city: store.city,
        state: store.state,
      });
    });

    chart.appear(1000, 100);

    return () => usaMap.dispose();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="home_sec1">
        <img src={homebannerbg} alt="background" className="background_img" />
        <Swiper
          grabCursor={false}
          // allowTouchMove={false}
          modules={[Autoplay, Navigation, Pagination]}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          // pagination={{
          //   el: ".banner-pagination",
          //   clickable: true,
          //   /*Return bullets as numbers*/
          //   renderBullet: function (activeIndex, className) {
          //     return (
          //       '<span class="' +
          //       className +
          //       '">' +
          //       "0" +
          //       (activeIndex + 1) +
          //       "</span>"
          //     );
          //   },
          // }}
          className="banner_slider"
        >
          <SwiperSlide>
            <HomeBanner01 />
            <div className="cta_wrapper">
              <CommonCta linkText="PRODUCT" linkCta link={productsURL} />
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="banner_sec">
              <h1>Banner 02 dummy</h1>
            </div>
          </SwiperSlide> */}
          {/* <div className="pagination_wrapper">
            <div className="banner-pagination"></div>
            <img
              src={paginationIcon}
              alt=""
              className="pag_icon"
              loading="lazy"
            />
          </div> */}
        </Swiper>
      </section>

      <section className="home_ticker_sec">
        <img src={tickerbg} alt="background" className="background_img" />
        <div className="my_container full_width zero_padding">
          <Swiper
            modules={[Autoplay]}
            grabCursor={false}
            allowTouchMove={false}
            autoplay={{
              enabled: true,
              delay: 0,
              pauseOnMouseEnter: false,
              disableOnInteraction: false,
            }}
            loop={true}
            slidesPerView="auto"
            spaceBetween={0}
            speed={4999}
            className="ticker_swiper"
          >
            {tickerList}
          </Swiper>
        </div>
      </section>

      <section className="home_sec2">
        <img
          src={width > 767 ? yellowBackground : mbYellowBackground}
          alt="background"
          className="background_img"
        />

        <div className="my_container">
          <div className="row taste_row">
            <div className="col-lg-5 col-xl-6 order-lg-2 taste_col">
              <div className="content_wrapper" data-aos="fade-up">
                <h2 className="heading" data-aos="fade-up">
                  Your Gateway to Authentic Gujarati Flavors
                </h2>
                <p className="desc" data-aos="fade-up">
                  Garvi Gujarat, is where tradition meets taste! We bring the
                  vibrant flavors of Gujarat straight to your table, offering a
                  delicious array of snacks and frozen meals that celebrate the
                  region's culinary heritage.
                  <br />
                  <br />
                  <strong>Authentic Recipes:</strong> Lovingly passed down
                  through generations for a true taste of Gujarat.
                  <br />
                  <strong>Premium Quality:</strong> Made with the finest
                  ingredients for unmatched freshness and flavor.
                  <br />
                  <strong>Convenient Access:</strong> Available at your nearest
                  South Asian grocery store.
                  <br />
                  <strong>Wide Variety:</strong> From savory snacks to wholesome
                  meals, there's something for everyone.
                  <br />
                  <strong>Hygienic Standards:</strong> Prepared in a clean, safe
                  environment for your peace of mind.
                  <br/>
                  <br/>
                  Rediscover the essence of Gujarat with Garvi Gujarat!
                </p>
                {width > 767 && (
                  <div className="cta_wrapper" data-aos="fade-up">
                    <CommonCta linkText="KNOW MORE" linkCta link={aboutURL} />
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-7 col-xl-6 order-lg-1 taste_col">
              {width > 767 ? (
                <div className="row_data">{tasteList}</div>
              ) : (
                <>
                  <div className="slider_wrapper">
                    <Swiper
                      slidesPerView={4}
                      loop={true}
                      spaceBetween={36}
                      grabCursor={true}
                      modules={[Autoplay, Navigation]}
                      // autoplay={{
                      //   delay: 2500,
                      //   disableOnInteraction: false,
                      // }}
                      navigation={{
                        prevEl: ".taste_left_arrow",
                        nextEl: ".taste_right_arrow",
                      }}
                      className="taste_swiper"
                      breakpoints={{
                        0: {
                          spaceBetween: 20,
                          slidesPerView: 1,
                        },
                        577: {
                          spaceBetween: 10,
                          slidesPerView: 2,
                        },
                      }}
                    >
                      {tasteSwiperList}
                    </Swiper>
                    <div className="arrow_wrapper">
                      <img
                        src={leftWhiteArrow}
                        alt="left arrow"
                        className="taste_left_arrow"
                      />
                      <img
                        src={rightWhiteArrow}
                        alt="right arrow"
                        className="taste_right_arrow"
                      />
                    </div>
                  </div>
                  <div className="cta_wrapper" data-aos="fade-up">
                    <CommonCta linkText="KNOW MORE" linkCta link={aboutURL} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec3">
        <img
          src={width > 767 ? greenBackground : mbGreenBackground}
          alt="background"
          className="background_img"
        />
        {width > 992 && (
          <img src={dandiyaDance} alt="dandiya" className="dandiya_dance" />
        )}
        <h2 className="heading" data-aos="fade-up">
          Authentic Gujarati Snacks for you
        </h2>
        <form className="form_wrapper" data-aos="fade-up">
          <select className="select_field" onChange={handleMbategoryhange}>
            {mbFilterList}
          </select>
        </form>

        <div className="guj_food_wrapper">
          {gujFoodList && (
            <Swiper
              slidesPerView={4}
              loop={products.length !== 0 ? true : false}
              spaceBetween={36}
              grabCursor={true}
              observer={true}
              observeParents={true}
              modules={[Autoplay, Navigation]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={{
                prevEl: ".food_left_arrow",
                nextEl: ".food_right_arrow",
              }}
              className="guj_food_swiper"
              breakpoints={{
                0: {
                  spaceBetween: 20,
                  slidesPerView: 1,
                },
                577: {
                  spaceBetween: 20,
                  slidesPerView: 2,
                },
                768: {
                  spaceBetween: 46,
                  slidesPerView: 2,
                },
                992: {
                  spaceBetween: 40,
                  slidesPerView: 3,
                },
                1194: {
                  spaceBetween: 36,
                  slidesPerView: 4,
                },
              }}
            >
              {gujFoodList}
            </Swiper>
          )}
          <div className="arrow_wrapper">
            <img
              src={leftWhiteArrow}
              alt="left arrow"
              className="food_left_arrow"
            />
            <img
              src={rightWhiteArrow}
              alt="right arrow"
              className="food_right_arrow"
            />
          </div>
        </div>
        {/* <div className="my_container">
          <div className="cta_wrapper">
            <CommonCta linkText="VIEW ALL" linkCta />
          </div>
        </div> */}
      </section>

      <section className="home_sec4">
        <img
          src={width > 767 ? lightGreenBackground : mbLightGreenBackground}
          alt="background"
          className="background_img"
        />
        {width > 767 ? (
          <img src={foodMaking} alt="food" className="food_making" />
        ) : null}

        <div className="my_container">
          <div className="map_container">
            <div className="row map_row">
              <div className="col-lg-6 map_col">
                <div className="content_wrapper">
                  <h2 className="heading" data-aos="fade-up">
                    {/* It's Time To Bring <br /> Garvi Gujarat At Home */}
                    It’s time to bring <br /> Garvi Gujarat home
                  </h2>
                  <p className="desc" data-aos="fade-up">
                    {/* Garvi Gujarat Offers An Assortment Of Authentic And
                    Traditional Gujarati Food Items To Its Customers Across The
                    US. So, What Are You Waiting For? */}
                    Experience Authentic Gujarati Snacks at Home with Garvi
                    Gujarat. Our selection of traditional Snack items is
                    available to customers across the US. Don’t miss out—order
                    today!
                  </p>
                  {/* <form className="form_wrapper">
                    <div className="search_container">
                      <div className="input_wrapper">
                        <input
                          className="input_field"
                          type="text"
                          placeholder="Enter Your Zip Code"
                        />
                      </div>
                      <button className="submit_btn">SUBMIT</button>
                    </div>
                  </form> */}
                  <div className="cta_wrapper" data-aos="fade-up">
                    <CommonCta
                      linkText="KNOW MORE"
                      link={whereToBuyURL}
                      linkCta
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 map_col">
                <div className="map_wrapper" data-aos="fade-up">
                  <div ref={mapRef} id="chartdiv" className="map_img"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="home_sec5">
        <img
          src={width > 767 ? yellowBackground : mbYellowBackground}
          alt="background"
          className="background_img"
        />
        <div className="media_wrapper">
          <div className="my_container">
            <h2 className="heading">Explore Media</h2>
          </div>
          <Swiper
            slidesPerView={3.8}
            loop={true}
            spaceBetween={103}
            centeredSlides={true}
            grabCursor={true}
            modules={[Autoplay, Navigation]}
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            // }}
            navigation={{
              prevEl: ".media_left_arrow",
              nextEl: ".media_right_arrow",
            }}
            className="media_swiper"
            breakpoints={{
              0: {
                spaceBetween: 37,
                slidesPerView: 2,
              },
              577: {
                spaceBetween: 45,
                slidesPerView: 2.5,
              },
              768: {
                spaceBetween: 50,
                slidesPerView: 3.1,
              },
              992: {
                spaceBetween: 50,
                slidesPerView: 3.5,
              },
              1194: {
                spaceBetween: 103,
                slidesPerView: 3.8,
              },
            }}
          >
            {mediaSwiperList}
          </Swiper>
          {width > 767 && (
            <div className="arrow_wrapper">
              <img
                src={leftWhiteArrow}
                alt="left arrow"
                className="media_left_arrow"
              />
              <img
                src={rightWhiteArrow}
                alt="right arrow"
                className="media_right_arrow"
              />
            </div>
          )}
        </div>
        <div className="my_container">
          <div className="cta_wrapper">
            <CommonCta linkText="VIEW ALL" linkCta link={() => false} />
          </div>
        </div>
      </section> */}

      <section className="home_sec6">
        <img
          src={width > 767 ? greenBackground : mbGreenBackground}
          alt="background"
          className="background_img"
        />
        <div className="my_container">
          <h2 className="heading">Our Television Commercials</h2>
        </div>
        <div className="vid_slider_wrapper">
          <Swiper
            slidesPerView={"auto"}
            loop={true}
            centeredSlides={true}
            spaceBetween={280}
            grabCursor={true}
            autoHeight={true}
            effect={"coverflow"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 300,
              modifier: 1,
              slideShadows: false,
            }}
            modules={[EffectCoverflow]}
            className="vid_slider"
            breakpoints={{
              0: {
                spaceBetween: 90,
              },
              577: {
                spaceBetween: 130,
              },
              768: {
                spaceBetween: 180,
              },
              992: {
                spaceBetween: 200,
              },
              1280: {
                spaceBetween: 240,
              },
              1920: {
                spaceBetween: 280,
              },
            }}
          >
            {commSwiperList}
          </Swiper>
        </div>
      </section>

      <section className="lightbox_wrapper">
        <FsLightbox toggler={isVideoOpen} sources={[vidLink]} />
      </section>

      <section className="home_footer">
        <Footer />
      </section>
    </>
  );
};

export default Home;
