import React, { useEffect } from "react";
import "./privacyPolicy.scss";
import {
  greenBackgroundCorner,
  lightGreenBackgroundRect,
  mbGreenBackgroundCorner,
  mbLightGreenBackground,
  privacyBanner,
} from "../../images";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { useWindowSize } from "react-use";
import Footer from "../../components/Footer/Footer";

const PrivacyPolicy = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="privacy_sec1">
        <CommonBanner
          linkText="Privacy Policy"
          title="Privacy Policy"
          img={privacyBanner}
          backImg={
            width > 767 ? lightGreenBackgroundRect : mbLightGreenBackground
          }
        />
      </section>

      <section className="privacy_sec2">
        <img
          src={width > 767 ? greenBackgroundCorner : mbGreenBackgroundCorner}
          alt="background"
          className="background_img"
        />
        <div className="my_container">
          <div className="privacy_container">
            <div className="para">
              <p className="desc top_desc">
                At Garvi Gujarat Private Limited, we value your privacy and are
                committed to safeguarding your personal information. This
                Privacy Policy outlines how we collect and use your data when
                you visit our website. By accessing our services, you agree to
                the collection and use of your data as described in this policy.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">1. Information We Collect:</h2>
              <p className="desc">
                As part of the registration process on our website or through
                online surveys, we may collect personally identifiable
                information about you, such as your:
              </p>
              <ul>
                <li>Name (first and last)</li>
                <li>Email address</li>
                <li>Mobile phone number</li>
                <li>Postal code</li>
                <li>
                  Demographic profile (age, gender, occupation, education,
                  address)
                </li>
                <li>Browsing information</li>
              </ul>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">2. How We Collect Information:</h2>
              <p className="desc">
                We collect personally identifiable information through voluntary
                registration or online surveys. Our website may also contain
                links to third-party websites; however, we are not responsible
                for their privacy practices.
                <br />
                Additionally, we use first-party and third-party cookies to
                optimize and serve ads based on your past visits to our website.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">3. How We Use Your Information:</h2>
              <p className="desc">We use your personal information to:</p>
              <ul>
                <li>
                  Provide personalized features and promotional offers through
                  our website and other channels.
                </li>
                <li>
                  Share relevant information with our business associates and
                  partners to offer services you have requested.
                </li>
                <li>
                  Preserve transaction history as required by law or policy.
                </li>
                <li>
                  Contact you as a survey respondent, notify you if you win a
                  contest, or send promotional materials from our contest
                  sponsors or advertisers.
                </li>
                <li>
                  Serve promotional and advertising materials through the Google
                  Ad network on third-party websites.
                </li>
              </ul>
              <p className="desc">
                You can opt-out of Google Analytics for Display Advertising and
                customize Google Display network ads using the Ads Preferences
                Manager.
              </p>
            </div>
            <div className="para" data-aos="fade-up">
              <h2 className="heading">4. Sharing Your Information:</h2>
              <p className="desc">
                We do not rent, sell, or share your personal information with
                third parties unless:
              </p>
              <ul>
                <li>We have your explicit permission.</li>
                <li>
                  It is necessary to provide the services you have requested.
                </li>
              </ul>
              <p className="desc">We may use your information to:</p>
              <ul>
                <li>
                  Investigate and prevent unlawful activities, suspected fraud,
                  potential threats to safety or security, or violations of our
                  website's terms of use.
                </li>
                <li>
                  Comply with subpoenas, court orders, legal authorities, or law
                  enforcement agencies.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
