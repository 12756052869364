import { images } from "../images";

export const tasteData = [
  {
    id: 1,
    img: images.taste1.image,
    alt: images.taste1.alt,
    title: "Gujarat – The Land of Tradition",
    desc: "Fairs like the Rann Utsav showcase the rich tapestry of Gujarati culture and tradition, highlighted by dazzling folk performances and immersive experiences.",
    // class: "",
  },
  {
    id: 2,
    img: images.taste2.image,
    alt: images.taste2.alt,
    title: "Rich, Artistic Dance Forms",
    desc: "The essence of Gujarati culture is captured with Colorful attire, vividly dyed dandiya sticks, and the energetic dance forms of Dandiya and Garba",
    class: "dance_img",
  },
  {
    id: 3,
    img: images.taste3.image,
    alt: images.taste3.alt,
    title: "Unique handicrafts",
    desc: "Gujarat’s vibrant culture is beautifully represented through traditional attire enhanced with tying & dyeing and block printing of fabrics",
    class: "handicrafts_img",
  },
  {
    id: 4,
    img: images.taste4.image,
    alt: images.taste4.alt,
    title: "Traditional Cuisines",
    desc: "Gujarati delicacies, including kadhi khichdi, khandvi, undhiyu, and aam shrikhand, are taking the global culinary scene by storm.",
    class: "cuisines_img",
  },
];

export const homeFilterData = [
  {
    id: 1,
    value: "All",
  },
  {
    id: 2,
    value: "Snacks",
  },
  {
    id: 3,
    value: "Frozen",
  },
];

export const gujFoodData = [
  {
    id: 1,
    img: images.food1.image,
    alt: images.food1.alt,
    title: "Bhavnagri Gathiya",
  },
  {
    id: 2,
    img: images.food2.image,
    alt: images.food2.alt,
    title: "Corn Chiwda",
  },
  {
    id: 3,
    img: images.food3.image,
    alt: images.food3.alt,
    title: "Bhel Sev",
  },
  {
    id: 4,
    img: images.food4.image,
    alt: images.food4.alt,
    title: "Yellow Banana Wafers",
  },
  {
    id: 5,
    img: images.food4.image,
    alt: images.food4.alt,
    title: "Yellow Banana Wafers",
  },
];

export const commSwiperData = [
  {
      id: 1,
      img: images.commSlider1.image,
      alt: images.commSlider1.alt,
      videoLink: "https://www.youtube.com/watch?v=lPrNTCMkw1A&t=19s&ab_channel=LaxmiFoodsBrand",
  },
  {
      id: 2,
      img: images.commSlider1.image,
      alt: images.commSlider1.alt,
      videoLink: "https://www.youtube.com/watch?v=SO7TdK-qen8&ab_channel=LaxmiFoodsBrand",
  },
  {
      id: 3,
      img: images.commSlider1.image,
      alt: images.commSlider1.alt,
      videoLink: "https://www.youtube.com/watch?v=xHorEs8SepQ&t=4s&ab_channel=LaxmiFoodsBrand",
  },
  {
      id: 4,
      img: images.commSlider1.image,
      alt: images.commSlider1.alt,
      videoLink: "https://www.youtube.com/watch?v=lPrNTCMkw1A&t=19s&ab_channel=LaxmiFoodsBrand",
  },
  {
      id: 5,
      img: images.commSlider1.image,
      alt: images.commSlider1.alt,
      videoLink: "https://www.youtube.com/watch?v=SO7TdK-qen8&ab_channel=LaxmiFoodsBrand",
  },
  {
      id: 6,
      img: images.commSlider1.image,
      alt: images.commSlider1.alt,
      videoLink: "https://www.youtube.com/watch?v=xHorEs8SepQ&t=4s&ab_channel=LaxmiFoodsBrand",
  },
];

export const mediaSwiperData = [
  {
    id: 1,
    img: images.media1.image,
    alt: images.media1.alt,
    title: "Bhavnagri Gathiya",
    date: "Ocotber 21, 2022",
    mediaLink: "https://www.youtube.com/watch?v=lPrNTCMkw1A&t=19s&ab_channel=LaxmiFoodsBrand",
  },
  {
    id: 2,
    img: images.media2.image,
    alt: images.media2.alt,
    title: "Sprouts Dhokla",
    date: "November 3, 2022",
    mediaLink: "https://www.youtube.com/watch?v=SO7TdK-qen8&ab_channel=LaxmiFoodsBrand",
  },
  {
    id: 3,
    img: images.media3.image,
    alt: images.media3.alt,
    title: "Methi Makai",
    date: "March 24, 2022",
    mediaLink: "https://www.youtube.com/watch?v=lPrNTCMkw1A&t=19s&ab_channel=LaxmiFoodsBrand",
  },
  {
    id: 4,
    img: images.media4.image,
    alt: images.media4.alt,
    title: "Bhavnagri Gathiya",
    date: "Ocotber 21, 2022",
    mediaLink: "https://www.youtube.com/watch?v=SO7TdK-qen8&ab_channel=LaxmiFoodsBrand",
  },
  {
    id: 5,
    img: images.media5.image,
    alt: images.media5.alt,
    title: "Sprouts Dhokla",
    date: "November 3, 2022",
    mediaLink: "https://www.youtube.com/watch?v=lPrNTCMkw1A&t=19s&ab_channel=LaxmiFoodsBrand",
  },
  {
    id: 6,
    img: images.media1.image,
    alt: images.media1.alt,
    title: "Methi Makai",
    date: "March 24, 2022",
    mediaLink: "https://www.youtube.com/watch?v=SO7TdK-qen8&ab_channel=LaxmiFoodsBrand",
  },
  {
    id: 7,
    img: images.media2.image,
    alt: images.media2.alt,
    title: "Methi Makai",
    date: "March 24, 2022",
    mediaLink: "https://www.youtube.com/watch?v=SO7TdK-qen8&ab_channel=LaxmiFoodsBrand",
  },
  {
    id: 8,
    img: images.media1.image,
    alt: images.media1.alt,
    title: "Methi Makai",
    date: "March 24, 2022",
    mediaLink: "https://www.youtube.com/watch?v=SO7TdK-qen8&ab_channel=LaxmiFoodsBrand",
  },
];
