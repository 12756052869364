import React, { useEffect, useState } from "react";
import "./products.scss";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { useWindowSize } from "react-use";
import {
  greenBackgroundCorner,
  lightGreenBackgroundRect,
  mbGreenBackgroundCorner,
  mbLightGreenBackground,
  productsBanner,
} from "../../images";
// import { filterData, productsData } from "../../data/productData";
import Footer from "../../components/Footer/Footer";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import config from "../../config/config";
const Products = () => {
  const { width } = useWindowSize();
  const [datas, setDatas] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategories, setActiveCategories] = useState(["all"]);

  const [loading, setloading] = useState(true);

  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const [dataCount, setdataCount] = useState(0);

  const dataLimit = 8;

  const base_url = config.api_url; //without trailing slash
  const image_url = config.image_url + "/product/"; //with trailing slash
  const azure_object_url = config.azure_object_url;

  const handleLoadMore = () => {
    setLoadMoreVisible(false);
    setdataCount(dataCount + dataLimit);
    fetchDatas(dataCount + dataLimit);
  };

  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      if (value === "all") {
        setActiveCategories([value]);
      } else {
        setActiveCategories([
          ...activeCategories.filter((item) => item !== "all"),
          value,
        ]);
      }
    } else {
      if (value != "all") {
        setActiveCategories(activeCategories.filter((item) => item !== value));
      }
    }
  };

  const handleMbategoryhange = (e) => {
    let category = e.target.value;

    console.log({ category });
    setActiveCategories([category]);
  };
  const fetchDatas = async (offset = 0) => {
    let parameters = {};

    if (activeCategories.length && !activeCategories.includes("all")) {
      parameters.categories = activeCategories;
    }
    if (offset) {
      parameters.offset = offset;
    }

    setloading(true);
    setLoadMoreVisible(false);
    const { data, hasMore } = await http.post(
      base_url + "products",
      parameters
    );

    if (data) {
      if (offset) {
        setDatas((prevState) => {
          return [...prevState, ...data];
        });
      } else {
        setDatas(data);
      }

      setLoadMoreVisible(hasMore);
    }

    setloading(false);
  };

  const fetchCategories = async () => {
    const { data } = await http.get(base_url + "product-categories");

    if (data) {
      setCategories([{ _id: "all", title: "All" }, ...data]);
    }
  };
  useEffect(() => {
    fetchDatas();
    fetchCategories();
  }, []);

  useEffect(() => {
    if (activeCategories.length === 0) {
      setActiveCategories(["all"]);
    }

    setdataCount(0);
    fetchDatas();
  }, [activeCategories]);

  const filterList = categories?.map((item, i) => (
    <div className="input_wrapper" key={i}>
      <label className={`label`}>
        <input
          type="checkbox"
          value={item._id}
          className="check_box"
          checked={activeCategories.includes(item._id)}
          onChange={handleCategoryChange}
        />
        <span className="value">{item.title}</span>
        <span className="checkmark"></span>
      </label>
    </div>
  ));

  const mbFilterList = categories?.map((item, i) => (
    <option value={item._id} key={i}>
      {item.title}
    </option>
  ));

  const Loader = () => {
    return "loading...";
  };
  const NotFound = () => {
    return "No data found";
  };

  const productList = datas?.map((item, i) => (
    <div className="col-md-6 col-sm-6 data_col" key={i} data-aos="fade-up">
      <div className="data_wrapper">
        <img src={azure_object_url + item.image} className="product_img" alt="products images" />
        <div className="details_wrapper">
          <h6 className="name">{item.title}</h6>
          <p className="desc">{item.description}</p>
          <h5 className="mt-1 weight_prod">Weight : {item.weight} g</h5>
          {/* <Link to={recipesURL} className="link">
            View Recipe
          </Link> */}
        </div>
      </div>
    </div>
  ));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="product_sec1">
        <CommonBanner
          linkText="Products"
          title="Products"
          img={productsBanner}
          backImg={
            width > 767 ? lightGreenBackgroundRect : mbLightGreenBackground
          }
        />
      </section>

      <section className="product_sec2">
        <img
          src={width > 767 ? greenBackgroundCorner : mbGreenBackgroundCorner}
          alt="background"
          className="background_img"
        />
        <div className="my_container">
          <div className="row product_row">
            <div className="col-lg-3 product_col">
              {width > 992 ? (
                <form className="form_wrapper" data-aos="fade-up">
                  <h6 className="form_title">Filter By</h6>
                  {filterList}
                </form>
              ) : (
                <form className="form_wrapper" data-aos="fade-up">
                  <select
                    className="select_field"
                    onChange={handleMbategoryhange}
                  >
                    {mbFilterList}
                  </select>
                </form>
              )}
            </div>
            <div className="col-lg-9 product_col">
              <div className="row data_row">{productList}</div>

              {loadMoreVisible && (
                <div className="btn_wrapper">
                  <button className="load_btn" onClick={handleLoadMore}>
                    Load More
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Products;
