import React, { useEffect, useState } from "react";
import "./contactus.scss";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import {
  contactBanner,
  greenBackground,
  yellowBackground,
  locationIcon,
  messageIcon,
  phoneIcon,
  mbGreenBackground,
  mbYellowBackground,
} from "../../images";
import CommonCta from "../../components/CommonCta/CommonCta";
import { useWindowSize } from "react-use";
import Joi from "joi";
import config from "../../config/config";
import http from "../../helpers/http";
import Footer from "../../components/Footer/Footer";

const ContactUs = () => {
  const { width } = useWindowSize();

  const [values, setValues] = useState({
    name: "",

    email: "",

    message: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [isThankyouVisible, setIsThankyouVisible] = useState(false);

  let base_url = config.api_url + "forms/contact";

  const schema = Joi.object({
    name: Joi.string().trim().required().label("Name"),

    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email Id"),

    message: Joi.string().trim().required().label("Message"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();

    let errors = await validate(values);
    setFormError(errors);

    console.log({ errors });
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url, values);

    if (data) {
      setValues({
        name: "",

        email: "",

        message: "",
      });

      setIsThankyouVisible(true);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.checked,
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="contact_sec1">
        <CommonBanner
          linkText="Contact Us"
          title="Contact Us"
          img={contactBanner}
        />
      </section>

      <section className="contact_sec2">
        <img
          src={width > 767 ? yellowBackground : mbYellowBackground}
          alt="background"
          className="background_img"
        />
        <div className="my_container">
          <div className="row contact_row">
            <div className="col-lg-5 order-lg-2 contact_col zero_padding">
              <div className="content_wrapper">
                <h2 className="heading" data-aos="fade-up">Get In Touch</h2>
                {/* <p className="desc">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod
                </p> */}

                <form className="form_container" onSubmit={handleSubmit}>
                  <div className="input_wrapper">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control input_field"
                        placeholder="Name*"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      <label>Name*</label>
                      <p className="error">{formError.name}</p>
                    </div>
                  </div>
                  <div className="input_wrapper">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control input_field"
                        placeholder="Email ID*"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <label>Email ID*</label>
                      <p className="error">{formError.email}</p>
                    </div>
                  </div>
                  {/* <div className="input_wrapper">
                    <div className="form-floating">
                      <input
                        type="tel"
                        className="form-control input_field"
                        placeholder="Contact No*"
                        name="tel"
                      />
                      <label htmlFor="name">Contact No*</label>
                      <p className="error"></p>
                    </div>
                  </div> */}
                  {/* <div className="input_wrapper">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control input_field"
                        placeholder="Select Location*"
                        name="text"
                      />
                      <label htmlFor="name">Select Location*</label>
                      <p className="error"></p>
                    </div>
                  </div> */}
                  {/* <div className="input_wrapper">
                    <select className="input_field select_field">
                      <option value="Select Location*" selected disabled>
                        Select Location*
                      </option>
                      <option value="select 1">Mumbai</option>
                      <option value="select 2">Navi Mumbai</option>
                    </select>
                    <p className="error"></p>
                  </div> */}
                  <div className="input_wrapper">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control input_field"
                        placeholder="Message*"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                      />
                      <label>Message*</label>
                      <p className="error">{formError.message}</p>
                    </div>
                  </div>
                  <div className="btn_wrapper">
                    <CommonCta linkText={loading ? "Loading.." : "SUBMIT"} />
                  </div>
                  <p
                    className="success_msg"
                    style={{ display: isThankyouVisible ? "block" : "none" }}
                  >
                    Thank you for your inquiry. Our team will reach out to you
                    in due course.
                  </p>
                </form>
              </div>
            </div>
            <div className="col-lg-7 order-lg-1 contact_col zero_padding" data-aos="fade-up">
              <div className="map_container">
                <iframe
                  title="google map frame"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6044.28094863529!2d-73.695829!3d40.758935!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2600a183540e5%3A0xecc3a1961eca0e60!2sHouse%20of%20Spices%20(India)%20Inc.!5e0!3m2!1sen!2sin!4v1716812981937!5m2!1sen!2sin"
                  width="100%"
                  height="907"
                  style={{ border: "0", margin: "0", padding: "0" }}
                  allowFullScreen
                  loading="lazy"
                  className="map_frame"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact_sec3">
        <img
          src={width > 767 ? greenBackground : mbGreenBackground}
          alt="background"
          className="background_img"
        />
        <div className="my_container">
          <h2 className="heading" data-aos="fade-up">Quick Support</h2>
          <div className="row support_row">
            <div className="col-md-4 support_col zero_padding" data-aos="fade-up">
              <div className="content_wrapper">
                <img
                  src={locationIcon}
                  alt="location icon"
                  className="support_icon"
                  loading="lazy"
                />
                <h4 className="sub_heading">VISIT US</h4>
                <p className="desc">
                  <a
                    href="https://maps.app.goo.gl/cWQyFzuhmoD9Z1PZ7"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    New York (Head Office) <br />
                    3000 Marcus Avenue Suite 2W10 <br /> Lake Success, NY 11042
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4 support_col zero_padding" data-aos="fade-up">
              <div className="content_wrapper">
                <img
                  src={phoneIcon}
                  alt="location icon"
                  className="support_icon"
                  loading="lazy"
                />
                <h4 className="sub_heading">CALL US</h4>
                <p className="desc call">
                  <a href="tel:+18334652964" rel="noreferrer" className="link">
                    +18334652964
                  </a>
                  {/* <br />
                  <a href="tel:+911234567890" rel="noreferrer" className="link">
                    +91 1234567890
                  </a> */}
                </p>
              </div>
            </div>
            <div className="col-md-4 support_col zero_padding" data-aos="fade-up">
              <div className="content_wrapper">
                <img
                  src={messageIcon}
                  alt="location icon"
                  className="support_icon"
                  loading="lazy"
                />
                <h4 className="sub_heading">EMAIL US</h4>
                <p className="desc">
                  <a
                    href="mailto:customerservice@houseofspicesindia.com"
                    rel="noreferrer"
                    className="link"
                  >
                    customerservice@houseofspicesindia.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contactus_footer">
        <Footer />
      </section>
    </>
  );
};

export default ContactUs;
