// General images
export { default as comBanBackgound } from "./general/banner_background.png";
export { default as rightArrow } from "./general/black_righ_arrow.svg";
export { default as locationIcon } from "./general/location.svg";
export { default as phoneIcon } from "./general/phone.svg";
export { default as messageIcon } from "./general/message.svg";
export { default as searchIcon } from "./general/search.svg";
export { default as yellowBackground } from "./general/yellow_background.png";
export { default as mbYellowBackground } from "./general/mb_yellow_background.png";
export { default as greenBackground } from "./general/green_background.png";
export { default as mbGreenBackground } from "./general/mb_green_background_1.png";
export { default as greenBackgroundCorner } from "./general/green_background_corner.png";
export { default as mbGreenBackgroundCorner } from "./general/mb_green_background_corner.png";
export { default as playIcon } from "./general/play_icon.svg";
export { default as whitPlayIcon } from "./general/white_play_icon.svg";
export { default as yellowDownArrow } from "./general/yellow_down_arrow.svg";
export { default as yellowUpArrow } from "./general/yellow_up_arrow.svg";
export { default as lightGreenBackground } from "./general/light_green_background.png";
export { default as lightGreenBackgroundRect } from "./general/light_green_background_ban.png";
export { default as mbLightGreenBackground } from "./general/mb_light_green_background.png";
export { default as hmaburgerbg } from "./general/hmaburger-bg.jpg";
export { default as garvipnglogo } from "./general/garvi-png-logo.png";
export { default as garvisvglogo } from "./general/garvi-svg-logo.svg";
export { default as linkedinFooter } from "./general/linkedin-footer.svg";
export { default as youtubeFooter } from "./general/youtube-footer.svg";
export { default as facebookFooter } from "./general/facebook-footer.svg";
export { default as instagramFooter } from "./general/instagram-footer.svg";
export { default as loader } from "./general/loader.gif";
export { default as noData } from "./general/no-data.svg";

// Homepage images
export { default as greenCircle } from "./homepage/green_circle.png";
export { default as shadow } from "./homepage/shadow_img.png";
export { default as rightWhiteArrow } from "./homepage/Right_Arrow.png";
export { default as leftWhiteArrow } from "./homepage/Left_Arrow.png";
export { default as cardBack } from "./homepage/card_back_img.png";
export { default as foodMaking } from "./homepage/gujarati_food.png";
export { default as homeMap } from "./homepage/map.png";
export { default as homebannerbg } from "./homepage/banner-bg.jpg";
export { default as tickerbg } from "./homepage/ticker-bg.png";
export { default as bannerillustrations } from "./homepage/banner-illustrations.svg";
export { default as circlebg } from "./homepage/circle-bg.png";
export { default as productCircle } from "./homepage/product-image-circle.png";
export { default as prodcut1 } from "./homepage/masala-khakra-pack.png";
export { default as prodcut2 } from "./homepage/banana-chips-yellow.png";
export { default as prodcut3 } from "./homepage/corn-chiwda-fop.png";
export { default as prodcut4 } from "./homepage/khaman-dhokla.png";
export { default as bannerIcon1 } from "./homepage/spice-icon.png";
export { default as bannerIcon2 } from "./homepage/kite-icon.svg";
export { default as bannerIcon3 } from "./homepage/bhakarwadi-icon.png";
export { default as bannerIcon4 } from "./homepage/banana-chip-icon.svg";
export { default as bannerIcon5 } from "./homepage/pngtree-icon.png";
export { default as paginationIcon } from "./homepage/pagination-icon.svg";
export { default as dandiyaDance } from "./homepage/dandiya.png";

// About images
export { default as aboutBanner } from "./about/about_banner.png";
export { default as productIcon } from "./about/Products_usp.svg";
export { default as tasteIcon } from "./about/Taste_usp.svg";
export { default as heartIcon } from "./about/Heart_of_Gujarat_usp.svg";
export { default as menPlaying } from "./about/men.png";
export { default as womenPlaying } from "./about/women.png";

// contact us images
export { default as contactBanner } from "./contact-us/contact_banner.png";

// Where to buy images
export { default as whereToBuyBanner } from "./where-to-buy/where_to_buy_banner.png";
export { default as map } from "./where-to-buy/map.png";
export { default as storeDefault } from "./where-to-buy/buy_1.png";
export { default as phoneLogo } from "./where-to-buy/phone_logo.svg";

// Recipes images
export { default as recipesBanner } from "./recipes/recipes_banner.png";

// Products images
export { default as productsBanner } from "./products/products_banner_1.png";

// Faqs images
export { default as faqBanner } from "./faqs/faq_banner.png";

// Privacy Policy images
export { default as privacyBanner } from "./privacy-policy/privacy_banner.png";

// Terms and conditions images
export { default as termsBanner } from "./terms-and-conditions/terms_banner.png";

export let images = {
  // Home Page Images Start
  taste1: {
    image: require("./homepage/Traditions.png"),
    alt: "product",
  },
  taste2: {
    image: require("./homepage/Artistic_Dance.png"),
    alt: "product",
  },
  taste3: {
    image: require("./homepage/Unique_Handicrafts.png"),
    alt: "product",
  },
  taste4: {
    image: require("./homepage/Traditional_Cuisines.png"),
    alt: "product",
  },
  food1: {
    image: require("./homepage/Bhavnagari_Gathiya.png"),
    alt: "product",
  },
  food2: {
    image: require("./homepage/Corn_Chiwda.png"),
    alt: "product",
  },
  food3: {
    image: require("./homepage/Bhel_Sev.png"),
    alt: "product",
  },
  food4: {
    image: require("./homepage/BANANA_CHIP.png"),
    alt: "product",
  },
  commSlider1: {
    image: require("./homepage/slider_1.png"),
    alt: "Recipes",
  },
  media1: {
    image: require("./homepage/Sprouts_Dhokla_media.png"),
    alt: "Recipes",
  },
  media2: {
    image: require("./homepage/Methi_Makai_media.png"),
    alt: "Recipes",
  },
  media3: {
    image: require("./homepage/Bhavnagri_Gathiya_media.png"),
    alt: "Recipes",
  },
  media4: {
    image: require("./homepage/media_4.png"),
    alt: "Recipes",
  },
  media5: {
    image: require("./homepage/media_5.png"),
    alt: "Recipes",
  },
  // Home Page Images End

  // Recipes Page Images Start
  recipes1: {
    image: require("./recipes/sev_puri.png"),
    alt: "Recipes",
  },
  recipes2: {
    image: require("./recipes/corn_chiwda.png"),
    alt: "Recipes",
  },
  recipes3: {
    image: require("./recipes/bhel.png"),
    alt: "Recipes",
  },
  recipeSlider1: {
    image: require("./recipes/slider_1.png"),
    alt: "Recipes",
  },
  // Recipes Page Images End

  // Product Page Images Start
  product1: {
    image: require("./products/Bhavnagari_Gathiya.png"),
    alt: "product",
  },
  product2: {
    image: require("./products/Bhel_Sev.png"),
    alt: "product",
  },
  product3: {
    image: require("./products/Corn_Chiwda.png"),
    alt: "product",
  },
  product4: {
    image: require("./products/BANANA_CHIP.png"),
    alt: "product",
  },
  // Product Page Images End


};
